import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'site-blurb',
  templateUrl: './blurb.component.html',
  styleUrls: ['./blurb.component.sass']
})
export class BlurbComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
